import BaseFirebaseService from './BaseFirebaseService';

export class EventService extends BaseFirebaseService {
  constructor() {
    super(`events`);
  }

  getEventStatus = (event) => {
    return this._callable('getEventStatus', event);
  };
}
