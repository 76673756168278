import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

// Your web app's Firebase configuration
// @TODO load firebase config per environment
let firebaseConfig = {
  apiKey: 'AIzaSyBMMMTBukMGh49Zu9rXQVnEJUPxLkhS5nU',
  authDomain: 'j3st-challenge-dev.firebaseapp.com',
  projectId: 'j3st-challenge-dev',
  storageBucket: 'j3st-challenge-dev.appspot.com',
  messagingSenderId: '1058941468995',
  appId: '1:1058941468995:web:a581f73d7bd12e93f3aff6',
  measurementId: 'G-NVQH6TWT7P',
};

if (process.env.REACT_APP_ENV === 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyDOTmOLc_izgbzvslp45xrKokArzgnAiYU',
    authDomain: 'j3st-challenge-prod.firebaseapp.com',
    projectId: 'j3st-challenge-prod',
    storageBucket: 'j3st-challenge-prod.appspot.com',
    messagingSenderId: '999011736235',
    appId: '1:999011736235:web:f3bf459e04d32e3cb575ee',
    measurementId: 'G-95H4V6D8HW',
  };
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export default firebase;
