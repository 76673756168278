import firebase from 'services/firebase';

class BaseFirebaseService {
  _collection = '';

  constructor(collection) {
    if (!collection) {
      throw new Error('Collection is required');
    }

    this._collection = collection;
    this._firebase = firebase;
  }

  collection = () => {
    return firebase.firestore().collection(this._collection);
  };

  _callable = (funcName, param) => {
    const func = this._firebase.functions().httpsCallable(funcName);
    return func(param).then((result) => result.data);
  };

  getItem = async (id) => {
    const item = await this.collection().doc(id).get();

    if (!item.exists) {
      return null;
    }

    const data = item.data();

    return {
      id,
      ...data,
    };
  };

  updateItem = (id, data) => {
    return this.collection()
      .doc(id)
      .update(data)
      .then(() => this.getItem(id));
  };

  createItem = (data, id) => {
    const doc = id ? this.collection().doc(id) : this.collection().doc();

    return doc
      .set(
        {
          ...data,
          timestamp: new Date().valueOf(),
        },
        {
          merge: true,
        }
      )
      .then(() => this.getItem(doc.id));
  };

  listItems = async (limit = 10, wheres = []) => {
    let query = this.collection();

    wheres.forEach((w) => {
      query = query.where(w.key, w.operator, w.value);
    });

    const items = await query.limit(limit).get();
    const result = [];

    items.forEach((doc) => {
      result.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    return result;
  };
}

export default BaseFirebaseService;
