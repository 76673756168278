import React, { useEffect, useRef, useState } from 'react';
import CHALLENGE_STATUS from 'constants/challengeStatus';
import { GAME_TYPE } from 'constants/gameType';
import useFirebaseCollection from 'hooks/useFirebaseCollection';
import ChallengeItem from 'containers/Challenge';
import Banner from 'containers/Banner';
import { Howl } from 'howler';
import currency from 'utils/currency';

import SwiperCore, { Autoplay, EffectCreative, Manipulation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import { useParams } from 'react-router-dom';
import AudioCompleted from 'assets/audio/challenge-completed.mp3';
import AudioSubmission from 'assets/audio/challenge-submited.mp3';

// const streamId = '1ZnT2JQVL6WeqEezMYRUxkF6dAI2';

SwiperCore.use([ Autoplay, EffectCreative, Manipulation ]);

const CREATIVE_EFFECT = {
  prev: {
    // will set `translateZ(-5000px)` on previous slides
    translate: [0, 0, -5000],
    opacity: 0,
  },
  next: {
    // will set `translateX(100%)` on next slides
    translate: ['100%', 0, 0],
  },
};

const challengeOverlayStatus = [CHALLENGE_STATUS.SUBMITTED, CHALLENGE_STATUS.ACTIVE, CHALLENGE_STATUS.COMPLETED];

function InfoSwiper() {
  const swiperRef = useRef(null);
  const params = useParams();
  const [pendingCount, setPendingCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [playCount, setPlayCount] = useState(0);
  const [swiperTimeoutId, setSwiperTimeoutId] = useState();

  const currentTime = new Date().valueOf();
  const MINUTE_MS = 60 * 1000;
  const DELAY_MS = 5 * 1000;
  const EVENT_PERIOD_MINS = 60;
  const SWIPER_HIDE_MINS = 2;

  const [challenges, , challengeLoaded] = useFirebaseCollection(
    `streams/${params.streamId}/challenges`,
    'status',
    'in',
    challengeOverlayStatus
  );

  const [parlays, , parlayLoaded] = useFirebaseCollection(
    `streams/${params.streamId}/parlays`,
    'status',
    'in',
    challengeOverlayStatus
  );

  const [bets, , betLoaded] = useFirebaseCollection(
    `streams/${params.streamId}/bets`,
    'status',
    'in',
    challengeOverlayStatus
  );

  const [events, , eventLoaded] = useFirebaseCollection(
    'events',
    'userIds',
    'array-contains',
    params.streamId
  );

  const [pendingChallenges, , pendingLoaded] = useFirebaseCollection(
    `streams/${params.streamId}/challenges`,
    'status',
    '==',
    CHALLENGE_STATUS.SUBMITTED,
    100
  );

  const [completedChallenges, , completedLoaded] = useFirebaseCollection(
    `streams/${params.streamId}/challenges`,
    'status',
    '==',
    CHALLENGE_STATUS.COMPLETED,
    100
  );

  const slides = [];
  let addNewBanner = true;

  // Challenges
  if (challengeLoaded && challenges.length > 0) {
    challenges.forEach((c) => {
      let addSlide = false;
      if (c.status === CHALLENGE_STATUS.SUBMITTED && c.timestamp > currentTime - SWIPER_HIDE_MINS * MINUTE_MS) {
        if (addNewBanner) {
          slides.push(
            <SwiperSlide key="challengeBanner">
              <Banner text="New Challenge" />
            </SwiperSlide>
          );
          addNewBanner = false;
        }
        addSlide = true;
      }
      
      if (addSlide || c.status === CHALLENGE_STATUS.ACTIVE) {
        slides.push(
          <SwiperSlide key={`challenge_${c.id}`}>
            <ChallengeItem
              amount={currency.formatCurrency(c.totalAmount)}
              title={c.title}
              description={c.username}
            />
          </SwiperSlide>
        );
      }
    });
  }
  
  // Events
  addNewBanner = true;
  let addCompletedEventBanner = true;

  if (eventLoaded && events.length > 0) {
    events.forEach((event) => {
      if (event.status === CHALLENGE_STATUS.ACTIVE) {
        if (addNewBanner && event.endTime - EVENT_PERIOD_MINS * MINUTE_MS > currentTime - SWIPER_HIDE_MINS * MINUTE_MS) {
          slides.push(
            <SwiperSlide key="startEventBanner">
              <Banner text="Event Started" />
            </SwiperSlide>
          );
          addNewBanner = false;
        }

        slides.push(
          <SwiperSlide key={`startEventSummary_${event.id}`}>
            <ChallengeItem
              amount={currency.formatCurrency(event.totalAmount)}
              title={event.title}
              description={`${event.userIds.length} Participants`}
            />
          </SwiperSlide>
        );
        // TODO: who added cash
        slides.push(
          <SwiperSlide key={`startEventStatus_${event.id}`}>
            <ChallengeItem
              challenge={event}
              amount={currency.formatCurrency(event.totalAmount)}
              title={event.title}
              showInfo
            />
          </SwiperSlide>
        );
      } else if (event.status === CHALLENGE_STATUS.COMPLETED && event.endTime + EVENT_PERIOD_MINS * MINUTE_MS > currentTime - 10 * MINUTE_MS) {
        if (addCompletedEventBanner && event.endTime + EVENT_PERIOD_MINS * MINUTE_MS > currentTime - SWIPER_HIDE_MINS * MINUTE_MS) {
          slides.push(
            <SwiperSlide key="endEventBanner">
              <Banner text="Event Ended" />
            </SwiperSlide>
          );
          addCompletedEventBanner = false;
        }

        slides.push(
          <SwiperSlide key={`endEvent_${event.id}`}>
            <ChallengeItem
              challenge={event}
              amount={currency.formatCurrency(event.totalAmount)}
            />
          </SwiperSlide>
        );
      }
    });
  }

  // Parlays for only Valorant now
  addNewBanner = true;
  if (parlayLoaded && parlays.length > 0) {
    parlays.filter((p) => p.gameType === GAME_TYPE.VALORANT).forEach((p) => {
      let addSlide = false;
      if (p.status === CHALLENGE_STATUS.SUBMITTED && p.timestamp > currentTime - SWIPER_HIDE_MINS * MINUTE_MS) {
        if (addNewBanner) {
          slides.push(
            <SwiperSlide key="parlayBanner">
              <Banner text="New Kill Challenge" />
            </SwiperSlide>
          );
          addNewBanner = false;
        }
        addSlide = true;
      }
      
      if (addSlide || p.status === CHALLENGE_STATUS.ACTIVE || p.status === CHALLENGE_STATUS.SUBMITTED) {
        slides.push(
          <SwiperSlide key={`challenge_${p.id}`}>
            <ChallengeItem
              amount={currency.formatCurrency(p.totalAmount)}
              title={`${p.kills} Kills + Win`}
              description={p.username}
            />
          </SwiperSlide>
        );
      }
    });
  };

  // Bets for only Valorant now
  addNewBanner = true;
  if (betLoaded && bets.length > 0) {
    bets.filter((b) => b.gameType === GAME_TYPE.VALORANT).forEach((b) => {
      if (b.status === CHALLENGE_STATUS.SUBMITTED && b.timestamp > currentTime - SWIPER_HIDE_MINS * MINUTE_MS) {
        if (addNewBanner) {
          slides.push(
            <SwiperSlide key="betBanner">
              <Banner text="New Bet Placed" />
            </SwiperSlide>
          );
          addNewBanner = false;
        }
      } else if (b.status === CHALLENGE_STATUS.COMPLETED && b.timestamp > currentTime - SWIPER_HIDE_MINS * MINUTE_MS) {
        slides.push(
          <SwiperSlide key="betTipBanner">
            <Banner text="Tip Received" />
          </SwiperSlide>
        );
        addNewBanner = false;
      }
    });
  };

  // Motivation Banner
  if (challengeLoaded && parlayLoaded && betLoaded && eventLoaded) {
    slides.push(
      <SwiperSlide key="j3sterBanner">
        <Banner text="J3STER.GG" />
      </SwiperSlide>
    );
    slides.push(
      <SwiperSlide key="motivationBanner">
        <Banner text="Challenge Your Streamer" />
      </SwiperSlide>
    );
  }

  useEffect(() => {
    if (pendingLoaded && pendingChallenges.length > pendingCount) {
      const sound = new Howl({
        src: [AudioSubmission],
      });

      sound.play();
    }

    setPendingCount(pendingChallenges.length);
  }, [pendingChallenges.length, pendingCount, pendingLoaded]);

  useEffect(() => {
    if (completedLoaded && completedChallenges.length > completedCount) {
      const sound = new Howl({
        src: [AudioCompleted],
      });

      sound.play();
    }

    setCompletedCount(completedChallenges.length);
  }, [completedChallenges.length, completedCount, completedLoaded]);

  useEffect(() => {
    clearTimeout(swiperTimeoutId);
    swiperRef.current.swiper.enable();
    swiperRef.current.swiper.slideTo(1);
  }, [playCount, slides.length]);

  return (
    <Swiper
      ref={swiperRef}
      className="infoSwiper"
      autoplay={{
        delay: DELAY_MS,
        disableOnInteraction: false,
      }}
      effect="creative"
      creativeEffect={CREATIVE_EFFECT}
      slidesPerView={1}
      speed={500}
      loop
      onSlideChange={(swiper) => {
        if (swiper.realIndex + 1 === slides.length) {
          const currentSlide = swiper.slides[swiper.activeIndex];
          setTimeout(() => {
            currentSlide.style.opacity = 0;
            currentSlide.nextSibling.style.display = 'none';
            swiper.disable();
          }, DELAY_MS);
          
          setSwiperTimeoutId(
            setTimeout(() => {
              swiper.enable();
              setPlayCount(playCount + 1);
            }, DELAY_MS + SWIPER_HIDE_MINS * MINUTE_MS)
          );
        }
      }}
    >
      {slides}
    </Swiper>
  );
}

export default InfoSwiper;
