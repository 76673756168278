import React from 'react';
// import ReactFreezeframe from 'react-freezeframe';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import InfoSwiper from 'containers/InfoSwiper';
import { getQuery } from 'utils/history';
import { isValidColor } from 'utils/color';

function App() {
  const query = getQuery();
  const override = {};

  if (isValidColor(query.primary)) {
    override.primary = {
      main: `#${query.primary}`,
    };
  }

  if (isValidColor(query.secondary)) {
    override.secondary = {
      main: `#${query.secondary}`,
    };
  }

  return (
    <div className="App">
      {/* <Box width={70} height={70}>
        <ReactFreezeframe
          src="/logo.gif"
          ref={ref}
          options={{
            trigger: false,
            overlay: false,
          }}
        />
      </Box>
      <button onClick={() => ref.current.start()}>Start</button> */}
      <ThemeProvider
        theme={(theme) =>
          createMuiTheme({
            ...theme,
            palette: {
              ...theme.palette,
              ...override,
            },
          })
        }
      >
        <InfoSwiper />
      </ThemeProvider>
    </div>
  );
}

export default App;
