import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import CountDown from 'components/common/CountDown';
import EVENT_TYPE from 'constants/eventType';
import { EventService } from 'services/event';
import EventSwiper from './EventSwiper';

const MINUTE_MS = 60000;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    height: '21px',
    width: 'calc(100% + 32px)',
    marginLeft: '-10px',
    textTransform: 'uppercase',
  },
}));

function EventInfo({ event }) {
  const classes = useStyles();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getEventStatus = async () => {
      const service = new EventService();
      const result = await service.getEventStatus(event);

      const userStats = result.map((stat) => {
        const user = event.users.find((u) => u.id === stat.userId);
        return {
          status: event.type === EVENT_TYPE.KILLS ? `${stat.kills} Kills` : `Rank ${stat.teamPlacement}`,
          username: user.username,
        };
      });

      setUsers(userStats);
    };

    getEventStatus();
    const interval = setInterval(getEventStatus, MINUTE_MS * 5); // Load every 5 minute
  
    return () => clearInterval(interval);
  }, []);

  return (
    <Box className={classes.root}>
      <CountDown seconds={event.endTime - (new Date().valueOf())} />
      {users.length > 0 && <EventSwiper users={users} hasStatus />}
    </Box>
  );
}

EventInfo.propTypes = {
  event: PropTypes.object,
};

export default EventInfo;
