import { useState, useEffect } from 'react';
import firebase from 'services/firebase';

function useFirebaseCollection(path, whereKey, whereOp, whereVal) {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);
  useEffect(() => {
    const loadData = () => {
      setLoading(true);

      let query = firebase.firestore().collection(path);

      if (whereKey) {
        query = query.where(whereKey, whereOp, whereVal);
      }

      return query.orderBy('timestamp', 'desc').onSnapshot((snap) => {
        const result = [];
        snap.forEach((doc) => {
          result.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setItems(result);
        setLoading(false);
        setLoaded(true);
      });
    };

    return loadData();
  }, [path, whereKey, whereOp, whereVal]);

  return [items, loading, loaded];
}

export default useFirebaseCollection;
