export function roundNumber(num, round = 2) {
  return Math.floor(num * 10 ** round) / 10 ** round;
}

// round up the amount
export function formatNumber(num, round = 0) {
  const result =
    typeof num === 'string'
      ? `${roundNumber(num, round)}`
      : (num / 100 || 0).toFixed(round);

  return result.replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

function getSym(str, currency = '$') {
  return `${currency}${str}`;
}

export function formatCurrency(amounts) {
  return getSym(formatNumber(amounts));
}

export default {
  formatNumber,
  formatCurrency,
  getSym,
};
