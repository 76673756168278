import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, useTheme } from '@material-ui/core';
import { SecondaryText } from 'components/common';
import CHALLENGE_STATUS from 'constants/challengeStatus';
import { ReactComponent as Logo } from 'assets/logo.svg';
import EventWinner from './EventWinner';
import EventInfo from './EventInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '61px',
    display: 'flex',
    alignItems: 'center',
  },
  shapeAmount: {
    width: `calc(${120.5 - Math.tan(33 * Math.PI / 180) * 61}px)`,
    height: '61px',
    opacity: 0.85,
    position: 'absolute',
    marginLeft: `calc(${Math.tan(33 * Math.PI / 180) * 61 / 2}px)`,
    backgroundColor: theme.palette.background.primary,
    transform: 'skew(-33deg)',
    display: 'flex',
    alignItems: 'center',    
  },
  textAmount: {
    width: `calc(${120.5 - Math.tan(33 * Math.PI / 180) * 61}px)`,
    color: theme.palette.text.primary,
    fontSize: theme.typography.h3.fontSize,
    transform: 'skew(33deg)',
    textAlign: 'center',
  },
  shapeContent: {
    // width: `calc(100% - ${Math.tan(33 * Math.PI / 180) * 61}px)`,
    width: '358px',
    height: '61px',
    opacity: 0.85,
    position: 'absolute',
    marginLeft: `calc(${127 - Math.tan(33 * Math.PI / 180) * 61 / 2}px)`,
    backgroundColor: theme.palette.background.primary,
    transform: 'skew(-33deg)',
  },
  container: {
    width: '250px',
    flexDirection: 'column',
    flexWrap: 'wrap',
    position: 'relative',
    left: '130px',
  },
  firstText: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.button.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    marginTop: '-6px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
  },
  secondText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textTransform: 'uppercase',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
  },
  logo: {
    position: 'absolute',
    paddingLeft: '400px'
  }
}));

function ChallengeItem({ challenge, amount, title, description, showInfo }) {
  const classes = useStyles();
  const theme = useTheme();
  const isEventCompleted = challenge?.status === CHALLENGE_STATUS.COMPLETED && challenge?.endTime !== undefined;
  const winnerKills = challenge?.winners !== undefined && challenge?.users.find((u) => u.id === challenge.winners[0])?.kills
  const newDescription = winnerKills ? `${winnerKills} Kills` : description || '';

  return (
    <Box className={classes.root}>
      <Box className={classes.shapeAmount}>
        <SecondaryText className={classes.textAmount}>
          {amount}
        </SecondaryText>
      </Box>
      <Box className={classes.shapeContent} />
      <Box className={classes.container}>
        {isEventCompleted ? (
          <EventWinner event={challenge} />
        ) : (
          <Box component={Typography} className={classes.firstText}>
            {title}
          </Box>
        )}
        {showInfo ? (
          <EventInfo event={challenge} />
        ) : (
          <Box component={Typography} className={classes.secondText}>
            {newDescription}
          </Box>
        )}
      </Box>
      <Box className={classes.logo}>
        <Logo style={{ width: 28, height: 26 }} fill={theme.palette.primary.main} />
      </Box>
    </Box>
  );
}

ChallengeItem.propTypes = {
  challenge: PropTypes.object,
  amount: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  showInfo: PropTypes.bool,
};

export default ChallengeItem;
