import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.warning.main,
    fontSize: theme.typography.fontSize,
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '1.5px',
  },
}));

function CountDown({ seconds }) {
  const classes = useStyles();
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    // exit early when we reach 0
    if (timeLeft <= 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft((t) => t - 1000);
    }, 1000);
  
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <Box component={Typography} className={classes.root}>
      Time:&nbsp;{moment(timeLeft >= 0 ? timeLeft : 0).format('mm:ss')}
    </Box>
  );
}

CountDown.propTypes = {
  seconds: PropTypes.number,
};

export default CountDown;