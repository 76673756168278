const history = require('history');
const qs = require('qs');

const { createBrowserHistory } = history;
const browserHistory = createBrowserHistory();

export function getQuery() {
  const { location } = browserHistory;
  return qs.parse(location.search, { ignoreQueryPrefix: true }) || {};
}

export default browserHistory;
