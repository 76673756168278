import { createMuiTheme } from '@material-ui/core/styles';

export const colors = {
  black0: '#000000',
  black1: '#161619',
  black2: '#12121230',
  gray0: '#00000051',
  gray1: '#979797',
  purple: '#7D22FF',
  white1: '#FEF9EC',
  white2: '#A49e9a',
  red: '#FF005C',
  green: '#0CFFCA',
};

export const fonts = {
  primary: 'IBMPlexSans',
  secondary: 'j3st',
};

const globalTheme = createMuiTheme({
  palette: {
    type: 'dark',
    background: {
      default: colors.black0,
      primary: colors.black1,
      secondary: colors.black2,
      gray: colors.gray0,
    },
    secondary: {
      main: colors.green,
      contrastText: colors.black0,
    },
    primary: {
      main: colors.purple,
      contrastText: colors.black0,
    },
    text: {
      primary: colors.white1,
      secondary: colors.white2,
      tertiary: colors.gray1,
    },
    error: {
      main: colors.red,
    },
    tertiary: {
      main: colors.black1,
    },
  },
  typography: {
    fontFamily: [
      'IBMPlexSans',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
    button: {
      fontSize: 16,
    },
    h2: {
      fontSize: 42,
    },
    h3: {
      fontSize: 26,
      fontWeight: 600,
    },
    h4: {
      fontSize: 20,
      fontWeight: 500,
      marginTop: 0,
      marginBottom: 0,
    },
    h6: {
      fontSize: 16,
      fontWeight: 300,
      textTransform: 'uppercase',
    },
  },
});

const theme = createMuiTheme(
  {
    overrides: {
      MuiDivider: {
        inset: {
          marginLeft: '56px',
        },
      },
      MuiListItem: {
        gutters: {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
      MuiAccordionSummary: {
        root: {
          backgroundColor: globalTheme.palette.background.secondary,
        },
      },
      MuiAccordionDetails: {
        root: {
          backgroundColor: globalTheme.palette.background.secondary,
        },
      },
    },
    props: {
      MuiTextField: {
        fullWidth: true,
        variant: 'outlined',
        margin: 'normal',
      },
      MuiInputLabel: {},
    },
  },
  globalTheme
);

export default theme;
