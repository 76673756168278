import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import currency from 'utils/currency';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.primary,
    fontSize: 'small',
  },
  iconText: {
    color: theme.palette.text.primary,
    fontSize: '12px',
    lineHeight: '16px',
  }
}));

function Currency({ amount, iconSize, ...props }) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" {...props}>
      <MonetizationOnIcon className={classes.icon} />
      <Box component={Typography} className={classes.iconText}>
        {currency.formatCurrency(+amount)}
      </Box>
    </Box>
  );
}

Currency.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconSize: PropTypes.string,
};

Currency.defaultProps = {
  iconSize: 'small',
};

export default Currency;
