import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { fonts } from 'theme';

const useStyles = makeStyles(() => ({
  text: {
    fontFamily: fonts.secondary,
    textTransform: 'uppercase',
  },
}));

function SecondaryText({ className, ...props }) {
  const classes = useStyles();

  return <Box className={clsx(classes.text, className)} {...props} />;
}

SecondaryText.propTypes = {
  className: PropTypes.string,
};

export default SecondaryText;
