import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import { SecondaryText } from 'components/common';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '61px',
    display: 'flex',
    alignItems: 'center',
  },
  shape: {
    width: '358px',
    height: '61px',
    opacity: 0.85,
    position: 'absolute',
    marginLeft: `calc(${127 - Math.tan(33 * Math.PI / 180) * 61 / 2}px)`,
    backgroundColor: theme.palette.background.primary,
    transform: 'skew(-33deg)',
  },
  container: {
    width: '300px',
    flexDirection: 'column',
    flexWrap: 'wrap',
    position: 'relative',
    left: '130px',
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h3.fontSize,
    textAlign: 'center',
  },
  firstText: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    marginTop: '-6px',
    width: '100%',
  },
  secondText: {
    color: theme.palette.secondary.main,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    fontWeight: 600,
    width: '100%',
  }
}));

// const baseUrl = (process.env.REACT_APP_BASE_URL || '').replace('https://', '');

function Banner({ text }) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.shape} />
      <Box className={classes.container}>
        <SecondaryText className={classes.title}>
          {text}
        </SecondaryText>
        {/* <Box component={Typography} className={classes.firstText}>
          Challenge Your Streamer
        </Box>
        <Typography
          component="a"
          href={`${process.env.REACT_APP_BASE_URL}/${streamerName}`}
          target="_blank"
          className={classes.secondText}
          noWrap
        >
          {baseUrl}/streams/{streamerName}
        </Typography> */}
      </Box>
    </Box>
  );
}

Banner.propTypes = {
  text: PropTypes.string,
};

export default Banner;
