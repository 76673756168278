import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useButtonStyles = makeStyles((theme) => ({
  outlined: {
    letterSpacing: '1.25px',
  },
  outlinedError: {
    color: theme.palette.error.main,
  },
}));

function CustomButton({
  loading,
  children,
  disabled,
  buttonProps,
  startIcon,
  variant,
  color,
  type,
  size,
  fullWidth,
  onClick,
  ...rest
}) {
  const classes = useButtonStyles();
  return (
    <Box
      display="inline-block"
      position="relative"
      width={fullWidth ? 1 : undefined}
      {...rest}
    >
      <Button
        disabled={loading || disabled}
        color={color}
        variant={variant}
        type={type}
        size={size}
        startIcon={startIcon}
        fullWidth={fullWidth}
        onClick={onClick}
        classes={classes}
        {...buttonProps}
      >
        {children}
      </Button>
      {loading && (
        <Box
          component={CircularProgress}
          position="absolute"
          top="50%"
          left="50%"
          mt="-12px"
          ml="-12px"
          size={24}
        />
      )}
    </Box>
  );
}

CustomButton.propTypes = {
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  variant: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  startIcon: PropTypes.node,
  buttonProps: PropTypes.object,
  onClick: PropTypes.func,
};

CustomButton.defaultProps = {
  variant: 'contained',
  color: 'primary',
};

export default CustomButton;
