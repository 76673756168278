import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import EventSwiper from './EventSwiper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    color: theme.palette.secondary.main,
    fontSize: theme.typography.button.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
    lineHeight: '16px',
    letterSpacing: '0.1px',
    overflow: 'hidden',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
  },
}));

function EventWinner({ event }) {
  const classes = useStyles();
  const users = event.users.filter((u) => event.winners?.includes(u.id));

  return (
    <Box className={classes.root}>
      {users.length > 0 ? (
        <>
          Event Winner - 
          <EventSwiper users={users} />
        </>
      ) : 'No winners'}
    </Box>
  );
}

EventWinner.propTypes = {
  event: PropTypes.object,
};

export default EventWinner;
