import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { Pagination, Autoplay, EffectCreative } from 'swiper';

import 'swiper/swiper.min.css';

SwiperCore.use([ Pagination, EffectCreative, Autoplay ]);

const useStyles = makeStyles((theme) => ({
  swiper: {
    flex: 1,
    marginLeft: (props) => 
      props.hasStatus ? 16 : 0,
  },
  slide: {
    width: '100%',
    height: '100%',
  },
  container: {
    display: 'flex',
  },
  statusText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize,
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '1.5px',
    marginRight: '8px',
  },
  username: {
    flex: 1,
    color: (props) =>
      props.hasStatus ? theme.palette.text.primary : theme.palette.secondary.main,
    fontSize: theme.typography.fontSize,
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '1.5px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
  }
}));

function EventSwiper({ users, hasStatus }) {
  const classes = useStyles({ hasStatus });

  return (
    <Swiper
      autoplay={{
        delay: 1000,
        disableOnInteraction: false,
      }}
      loop
      className={classes.swiper}
    >
      {users
        .map((user, index) => (
          <SwiperSlide key={`user-${index}`} className={classes.slide}>
            <Box className={classes.container}>
              <Box component={Typography} className={classes.statusText}>{user.status}</Box>
              <Box component={Typography} className={classes.username}>{user.username}</Box>
            </Box>
          </SwiperSlide>
        ))}
    </Swiper>
  );
}

EventSwiper.propTypes = {
  users: PropTypes.array,
  hasStatus: PropTypes.bool,
};

export default EventSwiper;
